@use "../base/variables" as v;
@use "../base/mixins" as m;

.dashboardScreen {
	@include m.tablet {
		display: flex;
	}

	&__main {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
