// Fuentes
$mainFont: "Roboto", sans-serif;

// Tamaño de Media Queries
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;
$monitor: 1440px;

// $primary: #114358;
// $secondary: #f2aa1f;
// $black: #090909;
// $textColor: #090909;
// $textColor: #667a8a;
// $white: #fff;
// $appBackground: #f1ece7;

$primary: #0f1626;
$secondary: #ab987a;
$black: #000;
$contrast: #ff533d;
$white: #ffffff;
$appBackground: #f5f5f5;

$spacing: 5rem;
