@use "variables" as v;

/** Media Queries **/
@mixin mobile {
	@media (min-width: v.$mobile) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: v.$tablet) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: v.$desktop) {
		@content;
	}
}

@mixin monitor {
	@media (min-width: v.$monitor) {
		@content;
	}
}

@mixin button($color, $text) {
	background-color: $color;
	font-size: 1.4rem;
	padding: 1.2rem 3rem;
	margin-top: 5rem;
	border-radius: 0.5rem;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	text-align: center;
	color: $text;
	text-decoration: none;
	transition: background-color 300ms ease;
	border: none;

	&:hover {
		cursor: pointer;
		background-color: lighten($color, 10);
	}

	// @include tablet {
	// 	width: auto;
	// }
}

@mixin grid($columns, $spacing) {
	display: grid;
	gap: $spacing;
	grid-template-columns: repeat($columns, 1fr);
}

@mixin transition($property, $time) {
	transition: $property $time ease-in-out;
}
