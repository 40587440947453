@use "../base/variables" as v;
@use "../base/mixins" as m;

.dashboardFooter {
	background-color: v.$white;
	box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 15%);
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0rem;
	width: 100%;

	&__menu {
		margin: 1rem;
		color: v.$primary;
		@include m.transition(height, 500ms);

		&--expanded {
			// aumentar segun el numero de links en el menu
			height: 32rem;
			overflow: visible;
		}

		&--collapsed {
			height: 0rem;
			overflow: hidden;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}

	&__button {
		font-size: 2.5rem;
		margin: 0 1rem;
		color: v.$primary;
	}
}
