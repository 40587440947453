@use "variables" as v;

#root {
	height: 100vh;
}

html {
	font-size: 62.5%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-family: v.$mainFont;
	font-size: 1.4rem;
	background-color: v.$appBackground;
}

p {
	color: v.$black;
	font-size: 2rem;
}

.container {
	width: 95%;
	max-width: 1200px;
	margin: 0 auto;
}

.sectionContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

a {
	text-decoration: none;
}

img {
	max-width: 100%;
	display: block;
}

h1,
h2,
h3 {
	margin: 0 0 v.$spacing 0;
	color: v.$primary;
}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.6rem;
}

h3 {
	font-size: 2.2rem;
}
